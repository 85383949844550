import React, { useEffect, useState } from 'react';
import TextExplanation from './components/TextExplanation';
import SummaryTable from './components/SummaryTable';
import AttributionBarChartComponent from './components/AttributionBarChart';
import PerfBarChartComponent from './components/PerfBarChart';
import './styles/grid.css'; // Import the grid CSS for layout

// Main App component that displays the Brinson Attribution visualizations
const App = () => {
  const [explanation, setExplanation] = useState('');
  const [tableData, setTableData] = useState([]);
  const [attributionChartData, setAttributionChartData] = useState([]);
  const [perfChartData, setPerfChartData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const allowedOrigins = [
    'https://sandbox.versaquant.com',
    'https://alpha.versaquant.com',
    'http://localhost:3000', // Ensure to use 'http' for localhost
  ];

  // Function to get the API server base URL based on the origin
const getApiBaseUrl = (origin) => {
  if (origin === 'https://sandbox.versaquant.com' || origin === 'http://localhost:3000') {
    return 'https://api.dev.versaquant.com:5000';
  } else {
    return 'https://api.versaquant.com:5000';
  }
};
  
  useEffect(() => {
    

    // Function to read message from Main APP
    const handleMessage = (event) => {
      console.log('Message received:', event.data)
      // Validate the origin
      if (!allowedOrigins.includes(event.origin))  { // add alpha and local here as well
        console.warn('Untrusted origin:', event.origin);
        console.log('Untrusted origin')
        return;
      }

      const { portID, accessToken } = event.data;
      console.log('Message received:', event.data)
      // Now you can use portID and accessToken in your micro frontend
      console.log('Received portID:', portID);
      //console.log('Received accessToken:', accessToken);

      // Get the base API URL based on the origin
      const apiBaseUrl = getApiBaseUrl(event.origin);

      // Construct the full API URL
      const apiUrl = `${apiBaseUrl}/run3/pm.getBrinsonModelData/%5B%22${portID}%22%2Cfalse%2Ctrue%5D`;

      fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          // Assuming API returns the following structure
          setExplanation(data.askAlpha);
          setTableData(data.summaryTable);
          setAttributionChartData(data.AggregateCategoryAttribution);
          setPerfChartData(data.AggregateCategoryReturns); 
          setIsReady(true);
        })
        .catch(error => console.error('Error fetching data:', error));
    };

    // Listen for messages from the main application
    window.addEventListener('message', handleMessage);
    console.log('Event listener added for message event');

    // Micro app is ready 
    console.log('Micro frontend is ready, sending ready signal to main app...');
    window.parent.postMessage({ type: 'microFrontendReady' }, '*');

    
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('message', handleMessage);
      console.log('Event listener removed for message event');
    };
  }, []);
  

  return (
    <div>
    {isReady && (
      <div className="grid-container">
        <TextExplanation title="Portfolio Attribution Analysis" explanation={explanation} />
        <SummaryTable data={tableData} />
        <AttributionBarChartComponent data={attributionChartData} />
        <PerfBarChartComponent data={perfChartData} />        
      </div>
    )}
  </div>
  );
};

export default App;
